<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <label for="profilePrefix">Prefix</label>
        <select
          class="form-control form-control-lg mb-2"
          id="profilePrefix"
          v-model="customer.prefixName"
          :disabled="view"
        >
          <option
            v-for="(prefix, index) in prefixes"
            :key="index"
            :value="prefix"
            >{{ prefix }}</option
          >
        </select>
        <TextInput
          class="mb-2"
          rules="required|customerName|max:30"
          name="First Name"
          id="profileFirstName"
          placeholder="Enter customer first name"
          v-model="customer.firstName"
          :disabled="view"
        />
        <TextInput
          class="mb-2"
          rules="customerName|max:25"
          name="Middle Name"
          id="profileMiddleName"
          placeholder="Enter customer middle name"
          v-model="customer.middleName"
          :disabled="view"
        />
        <TextInput
          class="mb-2"
          rules="required|customerName|max:30|min:2"
          name="Last Name"
          id="profileLastName"
          placeholder="Enter customer last name"
          v-model="customer.lastName"
          :disabled="view"
        />
        <label for="profileSuffix">Suffix</label>
        <select
          class="form-control form-control-lg mb-2"
          id="profileSuffix"
          v-model="customer.suffixName"
          :disabled="view"
        >
          <option
            v-for="(suffix, index) in suffixes"
            :key="index"
            :value="suffix"
            >{{ suffix }}</option
          >
        </select>
        <TextInput
          class="mb-2"
          rules="required|email|max:256"
          name="Email Address"
          id="registerEmail1"
          type="email"
          v-model="customer.email"
          placeholder="Enter customer email address"
          :disabled="view"
        />
        <div v-if="emailInUse" class="my-auto text-danger">
          Email is already in use
        </div>
        <TextInput
          class="mb-2"
          rules="required|maskNumMin:10"
          name="Primary Phone"
          id="profilePhone"
          placeholder="Enter customer phone number"
          v-model="customer.primaryPhoneNumber"
          mask="(###) ###-####"
          :disabled="view"
        />
        <TextInput
          class="mb-2"
          rules="maskNumMin:10"
          name="Alternate Phone"
          id="profileAlternatePhone"
          placeholder="Enter customer alternate phone number"
          v-model="customer.alternatePhoneNumber"
          mask="(###) ###-####"
          :disabled="view"
        />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-12">
            <TextInput
              :rules="
                edit
                  ? !!customer.user
                    ? 'required'
                    : ''
                  : 'required|min:8|username|max:20'
              "
              class="mb-2"
              name="Username"
              id="profileUsername"
              placeholder="Enter customer username"
              v-model="customer.username"
              :readonly="edit && !!customer.externalUserId"
              :disabled="view"
            />
            <div v-if="usernameIsAvailable" class="my-auto text-success mr-2">
              Username is available
            </div>
            <div
              v-else-if="usernameIsNotAvailable"
              class="my-auto text-danger mr-2"
            >
              Username is not available
            </div>
            <small
              class="form-text text-muted mb-2"
              v-if="suggestedUsernames && !!customer.user"
              >Suggested Username(s): {{ suggestedUsernames.toString() }}</small
            >
          </div>
        </div>
        <ValidationProvider
          rules="required"
          name="Country"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="profileCountry"
          >
            Country
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg mb-2"
            id="profileCountry"
            v-model="customer.country"
            v-bind="ariaInput"
            :disabled="view"
          >
            <option
              v-for="(country, index) in countries"
              :key="index"
              :value="country.abbreviation"
              >{{ getCountryName(country.abbreviation) }}</option
            >
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <TextInput
          class="mb-2"
          rules="required|address|max:70"
          name="Address 1"
          id="profileAddress1"
          placeholder="Enter customer street address"
          v-model="customer.street1"
          :disabled="view"
        />
        <TextInput
          class="mb-2"
          rules="address|max:70"
          name="Address 2"
          id="profileAddress2"
          placeholder="Enter customer street address, cont."
          v-model="customer.street2"
          :disabled="view"
        />
        <TextInput
          class="mb-2"
          rules="required|city|max:58"
          name="City"
          id="profileCity"
          placeholder="Enter customer city"
          v-model="customer.city"
          :disabled="view"
        />
        <TextInput
          class="mb-2"
          rules="required|city|max:20"
          name="State"
          id="profileState"
          placeholder="Enter customer state"
          v-model="customer.state"
          :disabled="view"
          v-if="isForeignAddress"
        />
        <ValidationProvider
          rules="required"
          name="State"
          v-slot="{ errors, ariaInput, ariaMsg }"
          v-else
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="profileState"
          >
            State
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg mb-2"
            id="profileState"
            v-model="customer.state"
            v-bind="ariaInput"
            :disabled="view"
          >
            <option
              v-for="(state, index) in states"
              :key="index"
              :value="state.abbreviation"
              >{{ state.name }}</option
            >
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <TextInput
          class="mb-2"
          :rules="isForeignAddress ? 'required' : 'required|min:5|zipCode'"
          :name="isForeignAddress ? 'Postal Code' : 'Zip Code'"
          id="profileZipCode"
          placeholder="Enter customer zip code"
          v-model="customer.zipCode"
          :mask="isForeignAddress ? '' : '#####-####'"
          :disabled="view"
        />
        <div class="row">
          <div class="col-lg-4">
            <label for="onlineUser">
              Account Creation
            </label>
            <input
              id="onlineUser"
              v-model="onlineUser"
              class="form-control form-control-lg"
              disabled
            />
          </div>
          <div class="col-lg-4">
            <label for="customerAccessedAccount">
              Customer Online Account Access
            </label>
            <input
              id="customerAccessedAccount"
              v-model="customerAccessedAccount"
              class="form-control form-control-lg"
              disabled
            />
          </div>
          <div class="col-lg-4">
            <ValidationProvider
              v-if="edit"
              name="Customer Status"
              v-slot="{ errors, ariaInput, ariaMsg }"
            >
              <label
                @click="$refs.select.focus()"
                :class="{ error: errors[0] }"
                for="profileCustomerStatus"
                >Customer Status</label
              >
              <select
                class="form-control form-control-lg mb-2"
                id="profileCustomerStatus"
                v-model="customer.customerStatus"
                v-bind="ariaInput"
                :disabled="view"
              >
                <option
                  v-for="(status, index) in customerStatuses"
                  :key="index"
                  :value="status.name"
                  >{{ status.name }}</option
                >
              </select>
              <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                <li
                  v-for="(error, index) in errors"
                  :key="index"
                  class="error"
                  v-bind="ariaMsg"
                >
                  {{ error }}
                </li>
              </ul>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!view">
      <hr />
    </div>
    <ValidationObserver ref="commentSave" disabled>
      <div class="row" v-if="!view">
        <div class="col-lg-12">
          <ValidationProvider
            name="Comments"
            rules="max:3000|required"
            v-slot="{ errors, ariaInput, ariaMsg }"
          >
            <label
              @click="$refs.select.focus()"
              :class="{ error: errors[0] }"
              for="profileComments"
              >Comments</label
            >
            <button
              type="button"
              class="btn btn-primary mb-2 float-right"
              @click="addComment"
              v-if="edit"
            >
              Add Comment +
            </button>
            <textarea
              class="form-control form-control-lg mb-2"
              id="profileComments"
              rows="4"
              v-model="customer.comment"
              v-bind="ariaInput"
            ></textarea>
            <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
              <li
                v-for="(error, index) in errors"
                :key="index"
                class="error"
                v-bind="ariaMsg"
              >
                {{ error }}
              </li>
            </ul>
          </ValidationProvider>
        </div>
      </div>
    </ValidationObserver>
    <div class="d-flex row mt-2" v-if="view || edit">
      <div class="col-lg-12">
        <div>
          <b-table
            ref="table"
            striped
            :fields="fields"
            :items="customer.comments"
            stacked="md"
            no-local-sorting
            small
            :busy="commentsLoading"
            bordered
            sort-icon-left
          >
            <template #table-busy>
              <div class="text-center my-2">
                <span
                  class="spinner-border spinner-border-sm align-middle"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import states from "@/store/modules/states.js";
import moment from "moment";
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import countries from "@/store/modules/countries.js";

export default {
  name: "AdminCustomerAddForm",
  components: {
    TextInput,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    customer: Object,
    usernameIsAvailable: { type: Boolean, default: false },
    usernameIsNotAvailable: { type: Boolean, default: false },
    emailInUse: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    view: { type: Boolean, default: false },
    customerStatuses: { type: Array, default: () => [] }
  },
  data() {
    return {
      prefixes: ["Mr", "Ms", "Mrs", "Dr", "Sir"],
      suffixes: ["Jr", "Sr", "III", "Esq", "MD", "PhD"],
      fields: [
        {
          key: "dateCreated",
          formatter: "formatDate",
          label: "Date Added",
          class: "dateColumn"
        },
        { key: "comment" },
        { key: "createdBy", label: "Username", class: "userColumn" }
      ],
      commentsLoading: false
    };
  },
  computed: {
    states() {
      return states;
    },
    countries() {
      return countries;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    onlineUser() {
      if (this.customer.createdByAdmin != null) {
        return this.customer.createdByAdmin ? "Agency" : "Customer Web";
      }
      return "";
    },
    customerAccessedAccount() {
      if (this.customer.verified != null) {
        return !this.customer.verified ? "No" : "Yes";
      }
      return "";
    },
    isForeignAddress() {
      return this.customer.country !== "US";
    },
    suggestedUsernames() {
      let usernames = [];
      if (this.customer.firstName != null && this.customer.lastName != null) {
        let first = this.customer.firstName.concat(" ".repeat(10));
        let last = this.customer.lastName.concat(" ".repeat(10));
        first = first.substring(0, 4).trim();
        last = last.substring(0, 4).trim();
        let number = Math.floor(Math.random() * 999);
        usernames = [...usernames, first + last + number];
      }
      if (this.customer.email != null) {
        usernames = [...usernames, this.customer.email.split("@")[0]];
      }

      return usernames.length > 0 ? usernames : null;
    }
  },
  methods: {
    getCountryName(abbv) {
      return this.countries.find(x => x.abbreviation === abbv).name;
    },
    async addComment() {
      this.$refs.commentSave.validate().then(async success => {
        if (success) {
          this.commentsLoading = true;
          let profileService = new AdminProfileService(this.tenantId);
          let newComment = await profileService.addCustomerComment(
            this.customer.id,
            this.customer.comment
          );

          this.customer = {
            ...this.customer,
            comments: [newComment.data, ...this.customer.comments]
          };
          this.customer.comment = null;
          this.commentsLoading = false;
          this.$refs.commentSave.reset();
        }
      });
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DDThh:mm:ss.SSSZ").format(
        "MM/DD/YYYY hh:mm A"
      );
    }
  }
};
</script>

<style>
.dateColumn {
  width: 200px !important;
}
.userColumn {
  width: 400px !important;
}
</style>
